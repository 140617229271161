import { doc, getDoc } from "firebase/firestore";
import firestore from "../../firestore";

export async function getGuest({ params }) {
  const guestRef = doc(firestore, "guests", params.id); // Get a reference to the document
  const guestSnap = await getDoc(guestRef); // Get the document
  let guest = null;

  if (guestSnap.exists()) {
    guest = guestSnap.data(); // Return the document data if the document exists
  }

  return { guest };
}
