import React, { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import { getGuests } from "./loaders/getGuests";
import { getGuest } from "./loaders/getGuest";
import ProtectedRoute from "./ProtectedRoute";
import Layout from "./Layout";

const Guests = lazy(() => import("./Guests"));
const CreateGuests = lazy(() => import("./CreateGuests"));
const Guest = lazy(() => import("./Guest"));
const Main = lazy(() => import("./Main"));

const Header = () => (
  <div style={{ fontSize: "22px" }}>
    <h1 className="text-center card-title" style={{ paddingTop: "260px" }}>
      ¡Hola!
    </h1>
    <div className="justify-content-center mt-5">
      <div className="col-lg-4">
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "500",
            marginBottom: "1rem",
            textAlign: "center",
            color: "#7d8557",
          }}>
          Estás invitado para ser testigo de la unión de dos corazones
        </p>
      </div>
    </div>
  </div>
);

const router = createBrowserRouter([
  { path: "/", element: <h1>Hello!</h1> },
  {
    path: "/:id",
    loader: getGuest,
    element: (
      <Suspense fallback={<Header />}>
        <Main />
      </Suspense>
    ),
  },
  {
    path: "/guests",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        loader: getGuests,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Guests />
          </Suspense>
        ),
      },
      {
        path: "create",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CreateGuests />
          </Suspense>
        ),
      },
      {
        path: ":id",
        loader: getGuest,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Guest />
          </Suspense>
        ),
      },
    ],
  },
]);

export default router;
