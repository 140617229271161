import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const isAuthenticated = Cookies.get("isAuthenticated");

  if (!isAuthenticated) {
    const password = prompt("Please enter the password");

    if (password !== process.env.REACT_APP_PASSWORD) {
      alert("Incorrect password");
      navigate("/");
      return null;
    }

    Cookies.set("isAuthenticated", true, { expires: 1 / 24 }); // Expires after 1 hour
  }

  return children;
};

export default ProtectedRoute;
